<template>
  <div>
    <v-app>
      <ui-cookie-popup />
      <ui-header />
      <div class="vv-app">
        <v-main style="max-width: 1400px">
          <slot />
        </v-main>
      </div>
      <ui-footer class="mt-14" />
    </v-app>
  </div>
</template>

<style>
.vv-app {
  width: 100%;
  max-width: 1400px;
  align-self: center;
  /* border: 1px solid rgba(128, 128, 128, 0.117); */
  position: relative;
}
</style>
